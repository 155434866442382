import {FormObject} from "@/utils/form-object";
import {PUSH_RIGHT, SET_CONTACTS, SET_PROJECTS, SET_USER} from "../mutations";
import {ADD_USER, CHANGE_PASSWORD, GET_USER, SAVE_USER} from "@/store/actions";
import axios from "axios";

const state = {
    user: new FormObject(),
    contacts: [],
};

const getters = {
    user: state => state.user || new FormObject(),
    contacts: state => state.contacts || [],
};

const actions = {
    [GET_USER]: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/user',
                method: 'GET',
            })
                .then(resp => {
                    commit(SET_USER, resp.data.data);
                    commit(SET_PROJECTS, resp.data.data.projects);
                    commit(SET_CONTACTS, resp.data.data.contacts);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SAVE_USER]: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/user',
                method: 'PUT',
                data: data,
            })
                .then(resp => {
                    commit(SET_USER, resp.data.data);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [ADD_USER]: ({commit}, {project_id, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/project/' + project_id + '/user',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_RIGHT, resp.data.data);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [CHANGE_PASSWORD]: (_, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/user/changePassword',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

const mutations = {
    [SET_USER]: (state, user) => {
        state.user = new FormObject({
            id: user.id,
            name: user.name,
        })
    },
    [SET_CONTACTS]: (state, contacts) => {
        state.contacts = contacts
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}