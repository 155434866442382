import axios from "axios";
import {GET_RESTORE_CODE, LOGIN, LOGOUT, REGISTRATION, RESTORE} from "../actions";

const state = {};

const getters = {};

const actions = {
    [LOGIN]: (_, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/auth/login',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [REGISTRATION]: (_, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/auth/registration',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [GET_RESTORE_CODE]: (_, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/auth/getRestoreCode',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [RESTORE]: (_, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/auth/restore',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [LOGOUT]: () => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/auth/logout',
                method: 'POST',
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
}