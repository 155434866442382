import _ from 'lodash';

export class FormObject {
    constructor(obj) {
        obj = obj === undefined ? {} : obj;
        this.origin = obj;
        this.object = _.cloneDeep(obj)
    }

    isEqual() {
        return _.isEqual(this.origin, this.object);
    }

    reset() {
        this.object = _.cloneDeep(this.origin);
    }
}
